<template>
	<div class="my_study">
		<el-dialog :title="$t('job_detail.GZXQ')" :visible.sync="dialogVisible" width="30%">
			<div class="study_box">
				<div class="box_list">
					<div class="list">
						<div class="list_name">
							<!-- 公司名称 -->{{$t('task.GSMC')}}：
						</div>
						<div class="list_msg">
							四川大学
						</div>
					</div>
					<div class="list">
						<div class="list_name">
							<!-- 岗位名称 -->{{$t('task.GWMC')}}：
						</div>
						<div class="list_msg">
							四川大学四川大学四川大学四川大学四川大学
						</div>
					</div>
				</div>
				<div class="box_list">
					<div class="list">
						<div class="list_name">
							<!-- 任职时间 -->{{$t('task.RZSJ')}}：
						</div>
						<div class="list_msg">
							四川大学
						</div>
					</div>
					<div class="list">
						<div class="list_name">
							<!-- 岗位薪酬 -->{{$t('task.GWXC')}}：
						</div>
						<div class="list_msg">
							四川大学四川大学四川大学四川大学四川大学
						</div>
					</div>
				</div>
				<div class="box_list">
					<div class="list">
						<div class="list_name">
							<!-- 工作内容 -->{{$t('task.GZNR')}}：
						</div>
						<div class="list_msg">
							在校的一些经历以及一些突出的学习成绩介绍。在校的一些经历以及一些突出的学习成绩介绍。在校的一些经历以及一些突出的学习成绩介绍。在校的一些经历以及一些突出的学习成绩介绍。在校的一些经历以及一些突出的学习成绩介绍。在校的一些经历以及一些突出的学习成绩介绍。在校的一些经历以及一些突出的学习成绩介绍。在校的一些经历以及一些突出的学习成绩介绍。在校的一些经历以及一些突出的学习成绩介绍。在校的一些经历以及一些突出的学习成绩介绍。
						</div>
					</div>
				</div>
				<div class="box_list">
					<div class="list">
						<div class="list_name">
							<!-- 工作成绩 -->{{$t('my_msg.GZCJ')}}：
						</div>
						<div class="list_msg">
							在校的一些经历以及一些突出的学习成绩介绍。在校的一些经历以及一些突出的学习成绩介绍。在校的一些经历以及一些突出的学习成绩介绍。在校的一些经历以及一些突出的学习成绩介绍。在校的一些经历以及一些突出的学习成绩介绍。在校的一些经历以及一些突出的学习成绩介绍。在校的一些经历以及一些突出的学习成绩介绍。在校的一些经历以及一些突出的学习成绩介绍。在校的一些经历以及一些突出的学习成绩介绍。在校的一些经历以及一些突出的学习成绩介绍。
						</div>
					</div>
				</div>
			</div>
			
		</el-dialog>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				dialogVisible: true
			};
		},
		methods: {
			
		}
	};
</script>

<style lang="less" scoped>
	.my_study {
		.el-dialog{
			background-color: #FFFFFF;
		}
		
		.study_box{
			background-color: #EFF1F5;
			padding: 15px;
			margin-bottom: 30px;
			border-radius: 10px;
			line-height: 1.3;
			.box_list{
				display: flex;
				margin-bottom: 15px;
				.list{
					width: 100%;
					display: flex;
					align-items: center;
					.list_name{
						font-size: 14px;
						color: #2B2B2A;
						flex-shrink: 0;
					}
					.list_msg{
						font-size: 12px;
						color: #767670;
					}
				}
			}
		}
	}
</style>
